import diff from "fast-diff";

const INSERT = "insert";
const DELETE = "delete";

export function strdiff(s1, s2) {
  const d = diff(s1, s2);
  var index = 0;
  var changeArray = [];
  for (var [op, str] of d) {
    if (op === diff.INSERT) {
      changeArray.push([INSERT, [index, str]]);
      index += str.length;
    } else if (op === diff.DELETE) {
      changeArray.push([DELETE, [index, str.length]]);
    } else {
      index += str.length;
    }
  }
  return changeArray;
}

strdiff.INSERT = INSERT;
strdiff.DELETE = DELETE;

export function applyDiff(text, diff) {
  for (var [op, args] of diff) {
    if (op === strdiff.INSERT) {
      const [index, content] = args;
      text.insert(index, content);
    } else {
      const [index, length] = args;
      text.delete(index, length);
    }
  }
}

export function makeid(length) {
  var result = [];
  // var characters =
  //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var characters = "abcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}
