import { useRef, useState, useEffect, useCallback } from "react";

import { fabric } from "fabric";

import Hammer from "hammerjs";

import classNames from "classnames";

function zoomToFit(canvas) {
  let objects = canvas.getObjects();
  if (objects.length === 0) return;
  let rect = objects[0].getBoundingRect(true);
  rect.right = rect.left + rect.width;
  rect.bottom = rect.top + rect.height;
  for (const obj of objects) {
    let objRect = obj.getBoundingRect(true);
    if (rect.left > objRect.left) rect.left = objRect.left;
    if (rect.top > objRect.top) rect.top = objRect.top;
    if (rect.bottom < objRect.top + objRect.height)
      rect.bottom = objRect.top + objRect.height;
    if (rect.right < objRect.left + objRect.width)
      rect.right = objRect.left + objRect.width;
  }
  let rectHeight = rect.bottom - rect.top;
  let rectWidth = rect.right - rect.left;
  let marginPercent = 0.05;
  let margin = Math.max(rectHeight, rectWidth) * marginPercent;
  rectHeight = rectHeight + 2 * margin;
  rectWidth = rectWidth + 2 * margin;
  rect.bottom += margin;
  rect.top -= margin;
  rect.right += margin;
  rect.left -= margin;
  let wRatio = canvas.width / rectWidth;
  let hRatio = canvas.height / rectHeight;
  let scaleFactor = null;
  let vshift = 0,
    hshift = 0;
  if (wRatio < hRatio) {
    scaleFactor = canvas.width / rectWidth;
    let delta = (canvas.height - rectHeight * scaleFactor) / 2;
    hshift = -rect.left * scaleFactor;
    vshift = -rect.top * scaleFactor + delta;
  } else {
    scaleFactor = canvas.height / rectHeight;
    let delta = (canvas.width - rectWidth * scaleFactor) / 2;
    hshift = -rect.left * scaleFactor + delta;
    vshift = -rect.top * scaleFactor;
  }
  let vpt = [scaleFactor, 0, 0, scaleFactor, hshift, vshift];
  canvas.setViewportTransform(vpt);
}

export function pathsToSVG(paths) {
  const element = document.createElement("canvas");

  element.style.display = "none";
  document.body.appendChild(element);

  const canvas = new fabric.Canvas(element, {
    isDrawingMode: false,
    selection: false,
  });
  fabric.util.enlivenObjects(paths, (pathObjects) => {
    for (let path of pathObjects) {
      canvas.add(path);
    }
  });
  zoomToFit(canvas);
  const svg = canvas.toSVG();
  canvas.dispose();
  document.body.removeChild(element);
  return svg;
}

const colorPalette = ["#222222", "#375E97", "#FB6542", "#FFBB00", "#3F681C"];
const defaultColor = colorPalette[0];
const defaultPencilWidth = 7;
const defaultEraserWidth = 50;

const TOOL_HAND = "hand";
const TOOL_PENCIL = "pencil";
const TOOL_ERASER = "eraser";

function Toolbar({
  fullscreen,
  toggleFullscreen,
  onUndo,
  onZoomToFit,
  onToolSelect,
  onPencilWidth,
  onPencilColor,
  onEraserWidth,
  onClearCanvas,
}) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [tool, setTool] = useState(TOOL_PENCIL);
  const [pencilWidth, setPencilWidth] = useState(defaultPencilWidth);
  const [pencilColor, setPencilColor] = useState(defaultColor);
  const [eraserWidth, setEraserWidth] = useState(defaultPencilWidth);

  return (
    <>
      <div>
        {drawerOpen && (
          <div className="flex flex-col bg-gray-100 border-t border-l border-r rounded-none sm:rounded-t p-2 shadow">
            <div className="pb-0 sm:pb-2 pt-2 px-2">
              {tool === TOOL_HAND && (
                <div className="flex justify-between items-center"></div>
              )}
              {tool === TOOL_ERASER && (
                <div className="flex justify-end items-center">
                  <div className="flex flex-col">
                    <div className="mb-1 text-sm text-gray-800">Width</div>
                    <input
                      type="number"
                      className="bg-gray-200 rounded-lg p-1 w-16 text-right border"
                      value={eraserWidth}
                      onChange={(e) => {
                        setEraserWidth(e.target.value);
                        onEraserWidth(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              {tool === TOOL_PENCIL && (
                <div className="flex flex-wrap justify-between items-center">
                  <div className="flex flex-col">
                    <div className="mb-1 text-sm text-gray-800">Color</div>
                    <div className="text-xl flex items-center">
                      {colorPalette.map((color, index) => (
                        <button
                          className={classNames("mr-2 focus:outline-none", {
                            "text-2xl": pencilColor === color,
                          })}
                          style={{
                            color,
                          }}
                          onClick={() => {
                            setPencilColor(color);
                            onPencilColor(color);
                          }}
                          key={index}
                        >
                          <i className="fas fa-square"></i>
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="mb-1 text-sm text-gray-800">Width</div>
                    <input
                      type="number"
                      className="bg-gray-200 rounded p-1 w-16 text-right border"
                      value={pencilWidth}
                      onChange={(e) => {
                        setPencilWidth(e.target.value);
                        onPencilWidth(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col bg-gray-100 p-2 z-10 relative shadow">
          <div className="flex flex-wrap justify-center items-center">
            <button
              className={classNames(
                "px-4 py-2 rounded border border-gray-200 mr-1 mt-1 focus:outline-none bg-gray-100 hover:bg-gray-200",
                {
                  shadow: tool === TOOL_HAND,
                }
              )}
              onClick={() => {
                setDrawerOpen(false);
                setTool(TOOL_HAND);
                onToolSelect(TOOL_HAND);
              }}
            >
              <i className="far fa-hand-paper"></i>
            </button>
            <button
              className={classNames(
                "px-4 py-2 rounded border border-gray-200 mr-1 mt-1 focus:outline-none bg-gray-100 hover:bg-gray-200",
                {
                  shadow: tool === TOOL_PENCIL,
                }
              )}
              onClick={() => {
                if (tool === TOOL_PENCIL) {
                  setDrawerOpen((open) => !open);
                } else {
                  setDrawerOpen(true);
                }
                setTool(TOOL_PENCIL);
                onToolSelect(TOOL_PENCIL);
              }}
            >
              <i className="fas fa-pencil-alt"></i>
            </button>
            <button
              className={classNames(
                "px-4 py-2 rounded border border-gray-200 mr-1 mt-1 focus:outline-none bg-gray-100 hover:bg-gray-200",
                {
                  shadow: tool === TOOL_ERASER,
                }
              )}
              onClick={() => {
                if (tool === TOOL_ERASER) {
                  setDrawerOpen((open) => !open);
                } else {
                  setDrawerOpen(true);
                }
                setTool(TOOL_ERASER);
                onToolSelect(TOOL_ERASER);
              }}
            >
              <i className="fas fa-eraser"></i>
            </button>
            <div className="h-8 border border-gray-200 mr-2"></div>
            <button
              className="px-4 py-2 rounded border border-gray-200 mr-1 mt-1 focus:outline-none hover:bg-gray-200"
              onClick={onUndo}
            >
              <i className="fas fa-undo"></i>
            </button>
            <button
              className="px-4 py-2 rounded border border-gray-200 mr-1 mt-1 focus:outline-none hover:bg-gray-200"
              onClick={toggleFullscreen}
            >
              <i
                className={classNames("fas", {
                  "fa-compress": fullscreen,
                  "fa-expand": !fullscreen,
                })}
              ></i>
            </button>
            <button
              className="px-4 py-2 rounded border border-gray-200 mr-1 mt-1 focus:outline-none hover:bg-gray-200"
              onClick={onZoomToFit}
            >
              <i className="fas fa-compress-arrows-alt"></i>
            </button>
            <button
              className="px-4 py-2 rounded border border-gray-200 focus:outline-none hover:bg-gray-200"
              onClick={onClearCanvas}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function FabricBoard({
  paths,
  onUndo,
  onClearCanvas,
  onPathCreated,
  readOnly,
}) {
  const outerRef = useRef(null);
  const canvasRef = useRef(null);
  const canvas = useRef(null);
  const [fullscreen, setFullscreen] = useState(false);
  const savedOnPathCreated = useRef();
  const saveReadOnly = useRef();

  const tool = useRef(TOOL_PENCIL);
  const pencilWidth = useRef(defaultPencilWidth);
  const pencilColor = useRef(defaultColor);
  const eraserWidth = useRef(defaultEraserWidth);

  useEffect(() => {
    savedOnPathCreated.current = onPathCreated;
  }, [onPathCreated]);

  useEffect(() => {
    saveReadOnly.current = readOnly;
  }, [readOnly]);

  useEffect(() => {
    canvas.current = new fabric.Canvas(canvasRef.current, {
      isDrawingMode: true,
      selection: false,
    });
    fabric.Object.prototype.selectable = false;
    canvas.current.freeDrawingBrush = new fabric.PencilBrush(canvas.current);
    canvas.current.freeDrawingBrush.width = 7;
    canvas.current.on("path:created", (e) => {
      const path = e.path;
      savedOnPathCreated.current(path);
    });
    canvas.current.on("mouse:down", (opt) => {
      if (tool.current !== TOOL_HAND) {
        return;
      }
      if (saveReadOnly.current === true) {
        return;
      }
      canvas.current.isDragging = true;
      canvas.current.selection = false;
      canvas.current.lastPosX = opt.pointer.x;
      canvas.current.lastPosY = opt.pointer.y;
    });
    canvas.current.on("mouse:move", (opt) => {
      if (tool.current !== TOOL_HAND) {
        return;
      }
      if (saveReadOnly.current === true) {
        return;
      }
      if (canvas.current.isDragging) {
        var vpt = canvas.current.viewportTransform;
        vpt[4] += opt.pointer.x - canvas.current.lastPosX;
        vpt[5] += opt.pointer.y - canvas.current.lastPosY;
        canvas.current.requestRenderAll();
        canvas.current.lastPosX = opt.pointer.x;
        canvas.current.lastPosY = opt.pointer.y;
      }
    });
    canvas.current.on("mouse:up", (opt) => {
      if (tool.current !== TOOL_HAND) {
        return;
      }
      if (saveReadOnly.current === true) {
        return;
      }
      canvas.current.setViewportTransform(canvas.current.viewportTransform);
      canvas.current.isDragging = false;
      canvas.current.selection = false;
    });
    canvas.current.on("mouse:wheel", (opt) => {
      if (saveReadOnly.current === true) {
        return;
      }
      var delta = opt.e.deltaY;
      var zoom = canvas.current.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.current.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    let initialZoom = 1;
    let hammer = new Hammer.Manager(canvas.current.upperCanvasEl);
    let pinch = new Hammer.Pinch();
    hammer.add([pinch]);
    hammer.on("pinchstart", () => {
      if (tool.current !== TOOL_HAND) {
        return;
      }
      if (saveReadOnly.current === true) {
        return;
      }
      initialZoom = canvas.current.getZoom();
    });
    hammer.on("pinch", (e) => {
      if (tool.current !== TOOL_HAND) {
        return;
      }
      if (saveReadOnly.current === true) {
        return;
      }
      canvas.current.isDragging = false;
      let point = new fabric.Point(e.center.x, e.center.y);
      let zoom = initialZoom * e.scale;
      if (zoom > 20) {
        zoom = 20;
      }
      if (zoom < 0.01) {
        zoom = 0.01;
      }
      canvas.current.zoomToPoint(point, zoom);
    });
    outerRef.current.addEventListener("fullscreenchange", (event) => {
      let elem = event.target;
      let isFullscreen = document.fullscreenElement === elem;
      setFullscreen(isFullscreen);
    });
    const onResize = () => {
      if (canvas.current === null) {
        return;
      }
      canvas.current.setDimensions({
        height: outerRef.current.getBoundingClientRect().height,
        width: outerRef.current.getBoundingClientRect().width,
      });
    };
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
      canvas.current.clear();
      canvas.current.dispose();
      canvas.current = null;
      hammer = null;
      pinch = null;
    };
  }, []);

  useEffect(() => {
    if (canvas.current === null) {
      return;
    }
    canvas.current.clear();
    fabric.util.enlivenObjects(paths, (pathObjects) => {
      for (let path of pathObjects) {
        canvas.current.add(path);
      }
    });
    if (readOnly === true) {
      zoomToFit(canvas.current);
    }
  }, [paths, readOnly]);

  useEffect(() => {
    const ref = outerRef.current;
    if (ref === null) {
      return;
    }
    const f = (event) => {
      if (event.ctrlKey && event.key === "z") {
        onUndo();
      }
    };
    ref.addEventListener("keypress", f);
    return () => {
      ref.removeEventListener("keypress", f);
    };
  }, [onUndo]);

  function handleZoomToFit() {
    zoomToFit(canvas.current);
  }

  function selectPencilWidth(width) {
    if (canvas.current === null) {
      return;
    }
    pencilWidth.current = width;
    canvas.current.freeDrawingBrush.width = width;
  }

  function selectPencilColor(color) {
    if (canvas.current === null) {
      return;
    }
    pencilColor.current = color;
    canvas.current.freeDrawingBrush.color = color;
  }

  function selectEraserWidth(width) {
    if (canvas.current === null) {
      return;
    }
    eraserWidth.current = width;
    canvas.current.freeDrawingBrush.width = width;
  }

  const selectTool = useCallback((selectedTool) => {
    if (canvas.current === null) {
      return;
    }
    switch (selectedTool) {
      case TOOL_HAND:
        tool.current = selectedTool;
        canvas.current.selection = false;
        canvas.current.isDrawingMode = false;
        break;
      case TOOL_PENCIL:
        tool.current = selectedTool;
        canvas.current.isDrawingMode = true;
        canvas.current.freeDrawingBrush.width = pencilWidth.current;
        canvas.current.freeDrawingBrush.color = pencilColor.current;
        break;
      case TOOL_ERASER:
        tool.current = selectedTool;
        canvas.current.isDrawingMode = true;
        canvas.current.freeDrawingBrush.width = eraserWidth.current;
        canvas.current.freeDrawingBrush.color = "#ffffff";
        break;
      default:
        console.error("Unknown tool: ", selectTool);
        break;
    }
  }, []);

  useEffect(() => {
    if (readOnly === true) {
      selectTool(TOOL_HAND);
    }
  }, [readOnly, selectTool]);

  return (
    <>
      <div
        className="flex justify-center overflow-hidden h-full w-full relative bg-white outline-none"
        ref={outerRef}
        tabIndex="0"
      >
        <canvas height="500" width="500" ref={canvasRef}></canvas>
        {readOnly !== true && (
          <div className="absolute bottom-0 left-0 right-0 sm:right-auto mb-0 sm:mb-2 ml-0 sm:ml-2 text-sm sm:text-md">
            <Toolbar
              onUndo={onUndo}
              onZoomToFit={handleZoomToFit}
              fullscreen={fullscreen}
              toggleFullscreen={() => {
                if (!fullscreen) {
                  outerRef.current.requestFullscreen();
                } else {
                  document.exitFullscreen();
                }
              }}
              onPencilWidth={selectPencilWidth}
              onPencilColor={selectPencilColor}
              onEraserWidth={selectEraserWidth}
              onToolSelect={selectTool}
              onClearCanvas={onClearCanvas}
            />
          </div>
        )}
      </div>
    </>
  );
}

export function Board({ yarray, readOnly }) {
  const [paths, setPaths] = useState([]);
  const savedYarray = useRef();

  useEffect(() => {
    setPaths(yarray.toArray());
    const handler = (event) => {
      setPaths(event.target.toArray());
    };
    yarray.observe(handler);
    savedYarray.current = yarray;
    return () => {
      yarray.unobserve(handler);
    };
  }, [yarray]);

  function handlePathCreated(path) {
    savedYarray.current.push([path.toJSON()]);
  }

  function handleUndo() {
    if (savedYarray.current.length < 1) {
      return;
    }
    savedYarray.current.delete(savedYarray.current.length - 1, 1);
  }

  function handleClearCanvas() {
    savedYarray.current.delete(0, savedYarray.current.length);
  }

  return (
    <>
      <FabricBoard
        paths={paths}
        onPathCreated={handlePathCreated}
        onUndo={handleUndo}
        onClearCanvas={handleClearCanvas}
        readOnly={readOnly}
      />
    </>
  );
}
