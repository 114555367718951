import {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  createContext,
  useContext,
  Fragment,
} from "react";

import classNames from "classnames";

import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  SelectorIcon,
  ArrowSmUpIcon as ArrowUpIcon,
  ArrowSmDownIcon as ArrowDownIcon,
  PlusIcon
} from "@heroicons/react/solid";

import * as Y from "yjs";
import { WebrtcProvider } from "y-webrtc";
import { IndexeddbPersistence } from "y-indexeddb";

import JSZip from "jszip";
import { saveAs } from "file-saver";

import { strdiff, applyDiff, makeid } from "./utils";

import { Board, pathsToSVG } from "./Board";

import { Switch as HSwitch } from "@headlessui/react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  Link
} from "react-router-dom";

import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import highlight from "remark-highlight.js";
import math from "remark-math";
import remark2rehype from "remark-rehype";
import katex from "rehype-katex";

import "./App.css";
import "./markdown.css";
import "./default.css";

import { yCollab } from "y-codemirror.next";
import { basicSetup } from "./cm-setup.js";
import { EditorState } from "@codemirror/state";
import { EditorView, keymap } from "@codemirror/view";
import { defaultTabBinding } from "@codemirror/commands";
import { markdown } from "@codemirror/lang-markdown";

// localStorage.log = process.env.NODE_ENV === "production" ? "false" : "true";
localStorage.log = "false";

const SIGNALLING_SERVER = process.env.REACT_APP_SIGNAL_SERVER_URL;

const TURN_SERVER = process.env.REACT_APP_TURN_SERVER_URL;
const TURN_USERNAME = process.env.REACT_APP_TURN_USERNAME;
const TURN_PASSWORD = process.env.REACT_APP_TURN_PASSWORD;

/*
 * Custom React Hooks
 */

function useDoc(roomName) {
  const [doc, setDoc] = useState(new Y.Doc());
  useEffect(() => {
    const ydoc = new Y.Doc();
    const provider = new WebrtcProvider(roomName, ydoc, {
      signaling: [SIGNALLING_SERVER],
      filterBcConns: false,
      peerOpts: {
        config: {
          iceServers: [
            {
              urls: TURN_SERVER,
              username: TURN_USERNAME,
              credential: TURN_PASSWORD,
            },
          ],
        },
      },
    });
    ydoc.provider = provider;
    new IndexeddbPersistence(roomName, ydoc);
    setDoc((doc) => {
      doc.destroy();
      return ydoc;
    });
    return () => {
      provider.destroy();
      ydoc.provider = null;
      ydoc.destroy();
    };
  }, [roomName]);
  return doc;
}

function useObserveCallback(ytype, cb) {
  const saved = useRef();
  useEffect(() => {
    saved.current = cb;
  }, [cb]);
  useEffect(() => {
    if (!ytype) {
      return;
    }
    saved.current(ytype);
    const handler = (event) => {
      saved.current(event.target);
    };
    ytype.observe(handler);
    return () => {
      ytype.unobserve(handler);
    };
  }, [ytype]);
  return saved;
}

// Y.js
function handleDelete(yarray, index) {
  yarray.doc.transact(() => {
    if (index < 0 || index >= yarray.length) {
      return;
    }
    yarray.delete(index, 1);
  });
}
function handleMoveUp(yarray, index) {
  yarray.doc.transact(() => {
    if (index < 0 || index >= yarray.length) {
      return;
    }
    const newIndex = index - 1;
    if (newIndex < 0) {
      return;
    }
    const a = yarray.get(newIndex).clone();
    const b = yarray.get(index).clone();
    yarray.delete(newIndex, 2);
    yarray.insert(newIndex, [b, a]);
  });
}
function handleMoveDown(yarray, index) {
  yarray.doc.transact(() => {
    if (index < 0 || index >= yarray.length) {
      return;
    }
    const newIndex = index + 1;
    if (newIndex >= yarray.length) {
      return;
    }
    const a = yarray.get(index).clone();
    const b = yarray.get(newIndex).clone();
    yarray.delete(index, 2);
    yarray.insert(index, [b, a]);
  });
}

/*
 * State Management
 */

const RoomContext = createContext();

/* eslint-disable-next-line no-unused-vars */
function TextArea({ ytext, onDelete }) {
  const [text, setText] = useState("");

  useObserveCallback(ytext, (value) => {
    setText(value.toString());
  });

  function handleInput(event) {
    const diff = strdiff(text, event.target.value);
    applyDiff(ytext, diff);
    setText(event.target.value);
  }

  return (
    <div className="my-2 flex flex-col w-full">
      <div className="overflow-hidden rounded-lg">
        <textarea
          className="rounded-lg p-2 w-full"
          style={{ minHeight: "10rem" }}
          value={text}
          onChange={handleInput}
        ></textarea>
      </div>
    </div>
  );
}

/*
 * Generic Components
 */

function Toggle({ enabled, setEnabled, label }) {
  return (
    <HSwitch.Group>
      {label && <HSwitch.Label>{label}</HSwitch.Label>}
      <HSwitch
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? "bg-blue-600" : "bg-gray-200"
        } relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </HSwitch>
    </HSwitch.Group>
  );
}

function MarkdownEditor({ ytext }) {
  const editorRef = useRef();

  useLayoutEffect(() => {
    const awareness = ytext.doc.provider.awareness;
    awareness.setLocalStateField("user", {
      color: "#008833",
      name: "User",
    });
    let theme = EditorView.theme({
      "&": {
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
      "&.cm-focused": {
        outline: "none",
      },
    });
    const state = EditorState.create({
      doc: ytext.toString(),
      extensions: [
        basicSetup,
        keymap.of([defaultTabBinding]),
        markdown(),
        yCollab(ytext, awareness),
        theme,
        EditorView.lineWrapping,
      ],
    });
    const view = new EditorView({ state, parent: editorRef.current });
    return () => {
      view.destroy();
    };
  }, [ytext]);

  return <div ref={editorRef}></div>;
}

const TYPE_MARKDOWN = "markdown";
const TYPE_WHITEBOARD = "whiteboard";

const CELL_TYPES = {
  [TYPE_MARKDOWN]: {
    name: "Markdown",
  },
  [TYPE_WHITEBOARD]: {
    name: "Whiteboard",
  },
};

function Cell({ ymap }) {
  const [type, setType] = useState(TYPE_MARKDOWN);
  const { selectedCellIndex, setSelectedCellIndex } = useContext(RoomContext);
  const { index } = useContext(CellContext);
  const outerRef = useRef();

  useEffect(() => {
    const handler = () => {
      setSelectedCellIndex(index);
    };
    const ref = outerRef.current;
    ref.addEventListener("focusin", handler);
    return () => {
      if (ref) {
        ref.removeEventListener("focusin", handler);
      }
    };
  }, [setSelectedCellIndex, index]);

  useObserveCallback(ymap, (value) => {
    setType(value.get("type"));
  });

  let inner;
  switch (type) {
    case TYPE_MARKDOWN:
      let textContent = ymap.get("textContent");
      if (textContent === undefined) {
        textContent = new Y.Text();
        ymap.set("textContent", textContent);
        textContent = ymap.get("textContent");
      }
      inner = <MarkdownEditor ytext={textContent} />;
      break;
    case TYPE_WHITEBOARD:
      let paths = ymap.get("paths");
      if (paths === undefined) {
        paths = new Y.Array();
        ymap.set("paths", paths);
        paths = ymap.get("paths");
      }
      inner = <Board yarray={paths} />;
      break;
    default:
      inner = <div>oops! {type} is not supported</div>;
      break;
  }

  return (
    <div
      className={classNames("my-2 flex flex-col w-full rounded -mx-2 px-2", {
        "ring ring-blue-100": index === selectedCellIndex,
        "border-b": index !== selectedCellIndex,
      })}
    >
      <div className="overflow-hidden pt-2 pb-2" tabIndex="0" ref={outerRef}>
        {inner}
      </div>
    </div>
  );
}

const CellContext = createContext();

function CellList({ yarray }) {
  const [array, setArray] = useState([]);
  const elements = array.map((ymap, index) => {
    return (
      <CellContext.Provider
        key={index}
        value={{
          index,
        }}
      >
        <Cell ymap={ymap} />
      </CellContext.Provider>
    );
  });
  useObserveCallback(yarray, (value) => {
    setArray(value.toArray());
  });
  function handleClick() {
    yarray.doc.transact(() => {
      const element = new Y.Map();
      element.set("type", TYPE_MARKDOWN);
      element.set("id", makeid(32));
      yarray.push([element]);
    });
  }
  return (
    <>
      <div className="flex flex-col items-center">{elements}</div>
      <button
        className="group flex items-center justify-center h-14 w-full bg-white rounded-lg text-gray-500 hover:text-gray-600 text-sm font-mono"
        onClick={handleClick}
      >
        <PlusIcon className="w-4 h-4 text-gray-400 group-hover:text-gray-500 mr-1 mb-0.5" />
        Add Cell
      </button>
    </>
  );
}

/* eslint-disable-next-line no-unused-vars */
function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function Doc({ roomName }) {
  const doc = useDoc(roomName);
  const yarray = doc.getArray("array");
  const {
    selectedCellIndex,
    setSelectedCellIndex,
    previewMode,
    setPreviewMode,
  } = useContext(RoomContext);

  const [selectedCellYMap, setSelectedCellYMap] = useState(null);
  const [selectedCellType, setSelectedCellType] = useState(TYPE_MARKDOWN);

  useEffect(() => {
    setSelectedCellYMap(yarray.get(selectedCellIndex));
  }, [yarray, selectedCellIndex]);

  useObserveCallback(selectedCellYMap, (value) => {
    if (!selectedCellYMap) {
      return;
    }
    setSelectedCellType(selectedCellYMap.get("type"));
  });

  function handleExport() {
    let zip = new JSZip();
    let assets = zip.folder("assets");

    let output = "";
    for (let element of yarray) {
      const type = element.get("type");
      switch (type) {
        case TYPE_MARKDOWN:
          const content = element.get("textContent").toString();
          output += content;
          break;
        case TYPE_WHITEBOARD:
          let paths = element.get("paths");
          const svgSource = pathsToSVG(paths.toArray());
          const filename = `${element.get("id")}.svg`;
          assets.file(filename, svgSource);
          const link = `assets/${filename}`;
          const md = `![](${link})`;
          output += md;
          break;
        default:
          break;
      }
      output += "\n\n";
    }
    zip.file("README.md", output);
    zip
      .generateAsync({
        type: "blob",
        mimeType: "application/zip",
      })
      .then((content) => {
        saveAs(content, "output.zip");
      });
  }

  return (
    <>
      <div className="sticky top-0 z-50 bg-white border-b pb-2 -mx-2 px-2">
        <div className="flex flex-col bg-white rounded-t-lg text-sm py-2">
          <div className="z-50 flex justify-between">
            <Listbox
              className="w-full sm:w-72"
              value={selectedCellType}
              onChange={(value) => {
                if (selectedCellYMap != null) {
                  selectedCellYMap.set("type", value);
                  // setType(value);
                }
              }}
            >
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg border cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                  <span className="block truncate">
                    Cell Type: {CELL_TYPES[selectedCellType].name}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {[TYPE_MARKDOWN, TYPE_WHITEBOARD].map((typename, idx) => (
                      <Listbox.Option
                        key={idx}
                        className={({ active }) =>
                          `${
                            active
                              ? "text-amber-900 bg-amber-100"
                              : "text-gray-900"
                          }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                        }
                        value={typename}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`${
                                selected ? "font-medium" : "font-normal"
                              } block truncate`}
                            >
                              {CELL_TYPES[typename].name}
                            </span>
                            {selected ? (
                              <span
                                className={`${
                                  active ? "text-amber-600" : "text-amber-600"
                                }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <button
              className="px-2 py-2 text-gray-500 hover:text-gray-700 border rounded-lg ml-2"
              onClick={() => {
                handleDelete(yarray, selectedCellIndex);
              }}
            >
              Delete
            </button>
          </div>
          <div className="flex flex-wrap justify-between items-baseline mt-2">
            <div className="flex items-baseline py-1">
              <button
                className="px-2 py-2 text-gray-500 hover:text-gray-700 border rounded-lg mr-2"
                onClick={handleExport}
              >
                Save
              </button>
              <div className="flex">
                <Toggle
                  enabled={previewMode}
                  setEnabled={setPreviewMode}
                  label={<span className="mr-2 text-gray-600">Preview</span>}
                />
              </div>
            </div>
            <div className="flex justify-end sm:justify-end py-1">
              <button
                className="group flex items-center px-2 py-2 text-gray-500 hover:text-gray-700 border rounded-l-lg"
                onClick={() => {
                  handleMoveUp(yarray, selectedCellIndex);
                  setSelectedCellIndex(
                    (selectedCellIndex) => selectedCellIndex - 1
                  );
                }}
              >
                Move Up
                <ArrowUpIcon className="w-4 h-4 text-gray-400 group-hover:text-gray-500 ml-1" />
              </button>
              <button
                className="group flex items-center px-2 py-2 text-gray-500 hover:text-gray-700 border-t border-b border-r rounded-r-lg"
                onClick={() => {
                  handleMoveDown(yarray, selectedCellIndex);
                  setSelectedCellIndex(
                    (selectedCellIndex) => selectedCellIndex + 1
                  );
                }}
              >
                Move Down
                <ArrowDownIcon className="w-4 h-4 text-gray-400 group-hover:text-gray-500 ml-1" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {previewMode ? (
        <CellListPreview yarray={yarray} />
      ) : (
        <CellList yarray={yarray} />
      )}
    </>
  );
}

function getSavedRooms() {
  const rooms = JSON.parse(localStorage.getItem("rooms"));
  if (rooms === null) {
    return {};
  }
  return rooms;
}

function saveRoom(roomName) {
  let rooms = JSON.parse(localStorage.getItem("rooms"));
  if (rooms === null) {
    rooms = {};
  }
  rooms[roomName] = true;
  localStorage.setItem("rooms", JSON.stringify(rooms));
}

function deleteRoom(roomName) {
  let rooms = JSON.parse(localStorage.getItem("rooms"));
  if (rooms === null) {
    rooms = {};
  }
  delete rooms[roomName];
  localStorage.setItem("rooms", JSON.stringify(rooms));
}

function Room(props) {
  const roomName = props.match.params.roomName;
  const [selectedCellIndex, setSelectedCellIndex] = useState(null);
  const [previewMode, setPreviewMode] = useState(false);

  useEffect(() => {
    saveRoom(roomName);
  }, [])

  return (
    <div className="flex justify-center">
      <div className="h-full min-h-screen border-l border-r px-1 sm:px-4 mx-0 sm:-mx-4 max-w-prose w-full">
        <RoomContext.Provider
          value={{
            selectedCellIndex,
            setSelectedCellIndex,
            previewMode,
            setPreviewMode,
          }}
        >
          <Doc roomName={roomName} />
        </RoomContext.Provider>
      </div>
    </div>
  );
}

function MarkdownPreview({ ytext }) {
  const [text, setText] = useState("");

  useObserveCallback(ytext, (value) => {
    setText(value.toString());
  });

  return (
    <ReactMarkdown
      className="markdown"
      remarkPlugins={[gfm, highlight, math, remark2rehype, katex]}
    >
      {text}
    </ReactMarkdown>
  );
}

function CellPreview({ ymap }) {
  const [type, setType] = useState(TYPE_MARKDOWN);

  useObserveCallback(ymap, (value) => {
    setType(value.get("type"));
  });

  let inner;
  switch (type) {
    case TYPE_MARKDOWN:
      let textContent = ymap.get("textContent");
      if (textContent === undefined) {
        textContent = new Y.Text();
        ymap.set("textContent", textContent);
        textContent = ymap.get("textContent");
      }
      inner = <MarkdownPreview ytext={textContent} />;
      break;
    case TYPE_WHITEBOARD:
      let paths = ymap.get("paths");
      if (paths === undefined) {
        paths = new Y.Array();
        ymap.set("paths", paths);
        paths = ymap.get("paths");
      }
      inner = <Board yarray={paths} readOnly={true} />;
      break;
    default:
      inner = <div>oops! {type} is not supported</div>;
      break;
  }

  return (
    <div className="my-2 flex flex-col w-full">
      <div className="overflow-hidden rounded-b-lg">{inner}</div>
    </div>
  );
}

function CellListPreview({ yarray }) {
  const [array, setArray] = useState([]);
  const elements = array.map((ymap, index) => {
    return <CellPreview key={index} ymap={ymap} />;
  });
  useObserveCallback(yarray, (value) => {
    setArray(value.toArray());
  });
  return <>{elements}</>;
}

function Home() {
  const [roomName, setRoomName] = useState(
    `${makeid(4) + "-" + makeid(4) + "-" + makeid(4)}`
  );
  const [savedRooms, setSavedRooms] = useState([]);
  const history = useHistory();
  function handleClick() {
    history.push(`/room/${roomName}`);
  }
  function getSortedRooms() {
    const rooms = Object.keys(getSavedRooms());
    rooms.sort();
    return rooms
  }
  useEffect(() => {
    setSavedRooms(getSortedRooms())
  }, [])
  return (
    <div className="flex items-center justify-center w-full h-full min-h-screen max-h-screen bg-gray-100">
      <div className="bg-white p-4 max-w-lg w-full border border-gray-300 shadow text-gray-800 rounded-lg">
        <div className="mb-2 text-2xl">Start a new notebook</div>
        <div className="text-sm mb-4 text-gray-700">
          A notebook lets you organize information into different cells and
          export them to a single markdown document.
        </div>
        <div className="flex flex-row items-baseline">
          <input
            type="text"
            className="w-full p-2 rounded-l ring-2 ring-inset ring-gray-200 focus:outline-none focus:ring-blue-300"
            value={roomName}
            onChange={(event) => setRoomName(event.target.value)}
          />
          <button
            className="py-2 px-4 bg-gray-800 hover:bg-gray-900 text-white rounded-r"
            onClick={handleClick}
          >
            Open
          </button>
        </div>
        <div className="w-full border mt-10 mb-6 h-px"></div>
        <div className="mb-2 text-2xl">Open recent notebook</div>
        <div className="text-sm mb-2 text-gray-700">
          A notebook lets you organize information into different cells and
          export them to a single markdown document.
        </div>
        <div className="flex flex-col">
          {savedRooms.map((room, index) => (
            <div key={index} className="flex justify-between w-full p-4 border rounded my-2">
              <Link to={`/room/${room}`}>{room}</Link>
              <button onClick={() => {
                deleteRoom(room);
                setSavedRooms(getSortedRooms());
              }}>Delete</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="h-full w-full min-h-screen min-w-screen bg-white text-gray-700">
      <Router>
        <Switch>
          <Route path="/room/:roomName" component={Room}></Route>
          <Route path="/">
            <Home></Home>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
